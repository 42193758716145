<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Tipos de permisos'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        @setFilters="setFilters"
                        @loadModalData="loadModalData"
                        :perPage="[10, 25, 50, 100]"
                        :showAdvancedFilters="true"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                <v-text-field 
                                    outlined 
                                    label="Nombre del permiso" 
                                    v-model="filterNombre"
                                >
                                </v-text-field>
                            </v-col>

                        </template>

                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td>
                                    <div class="tblPrincipal">{{ item.name }}</div>
                                </td>
                                <td>
                                    <div class="tblPrincipal">{{ item.title }}</div>
                                </td>
                                <td>
                                    <div class="tblPrincipal">{{ item.description }}</div>
                                </td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    slot="activator"
                                                    class="botonHover"
                                                    icon
                                                    fab
                                                    dark
                                                    @click="abrirModal('update', item)"
                                                    small
                                                >
                                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    slot="activator"
                                                    class="botonHover"
                                                    depressed
                                                    icon
                                                    fab
                                                    dark
                                                    small
                                                    @click="eliminar(item)"
                                                >
                                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="600px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                                <div class="headerModalStart">
                                    <h2 class="titleModal_700_18">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoadingModal">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                    <v-row class="my-5">
                                        <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    label="Nombre del permiso" 
                                                    class="pa-0 ma-0" 
                                                    v-model="permiso.nombre" 
                                                    :error-messages="errors"
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Titulo" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    label="Titulo" 
                                                    class="pa-0 ma-0" 
                                                    v-model="permiso.title" 
                                                    :error-messages="errors"
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Descripción" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    label="Descripción" 
                                                    class="pa-0 ma-0" 
                                                    v-model="permiso.descripcion" 
                                                    :error-messages="errors"
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Categoría" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Categoría"
                                                    :items="itemsCategorias"
                                                    item-text="display_name"
                                                    item-value="clave"
                                                    persistent-hint
                                                    v-model="permiso.categoria_clave"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" 
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>

<script>
/* API's */
import apiPermisos from "@/api/permisos";

/* COMPONENTES */
import Datatable from "@/components/datatable/Datatable.vue";

//PLUGINS
import Notify from '@/plugins/notify';

export default {
    components: {
        "data-table": Datatable,
    },
    data() {
        return {
            datosLogin  : null,
            rol         : null,
            url         : "permisos/findPermissions",
            columns: [
                {
                    label: "Nombre",
                    name: "name",
                    filterable: false,
                },
                {
                    label: "Titulo",
                    name: "title",
                    filterable: false,
                },
                {
                    label: "Descripción",
                    name: "description",
                    filterable: false,
                },
                {
                    label: "Opciones",
                    name: "opciones",
                    filterable: false,
                    align: 'center'
                },
            ],
            filters: {
                paginate    : true,
                order_by    : 'id',
            },
            permiso: {
                id          : null,
                nombre      : null,
                descripcion : null,
                title       : null,
                categoria_clave: null,
            },
            
            tituloModal     : '',
            imagen          : '',
            accion          : '',
            isLoadingModal  : false,
            isLoading       : false,
            dialog          : false,
            isSaving        : false,
            itemsCategorias : [],

            filterNombre    : null,

        }
    },
    watch: {
        
    },
    methods: {
        async abrirModal(accion, data = null){
            await this.resetValues()
            this.accion         = accion;
            this.isLoadingModal = true;

            this.getCategorias();

            if(accion == "add"){
                this.tituloModal    = "Agregar permiso";
                this.imagen         = "/static/modal/brigadasCreate.svg";
                
            } else {
                this.tituloModal        = "Editar permiso";
                this.imagen             = "/static/modal/brigadasUpdate.svg";
                this.permiso.id             = data.id;
                this.permiso.nombre         = data.name;
                this.permiso.descripcion    = data.description;
                this.permiso.title          = data.title;
                this.permiso.categoria_clave= data.categoria_clave;
            }
            this.isLoadingModal  = false;
            this.dialog = true;
        },
        guardar(){
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                
                this.isLoading = true;
                if(this.accion === "add") {

                    apiPermisos.createPermissions(this.permiso).then((response) => {
                        Notify.Success("Operación exitosa", "El registro se guardó satisfactoriamente.");
                        this.$refs.tabla.getData();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {

                    apiPermisos.updatePermissions(this.permiso).then((response) => {
                        Notify.Success("Operación exitosa", "El registro se actualizó satisfactoriamente.");
                        this.$refs.tabla.getData();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        eliminar(permiso){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el permiso?",
                "Eliminar",
                () => {

                    this.isLoading = true;
                    let param = {
                        permisos:[]
                    }
                    param.permisos.push(permiso.id)
                    apiPermisos.deletePermissions(param).then((response) => {
                        Notify.Success("Registro eliminado", "El registro ha sido eliminado satisfactoriamente");
                        this.cerrarModal();
                        this.$refs.tabla.getData();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        cerrarModal(){
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        async resetValues() {
            this.permiso    = {
                id          : null,
                nombre      : null,
                descripcion : null,
                title       : null,
                categoria_clave: null,
            };

            this.filterNombre   = null;

            this.$nextTick(() => {

                if(this.$refs.form){
                    this.$refs.form.reset();
                }
            });
        },
        async loadModalData(){
            //
        },
        async setFilters(){
            
            //FILTROS


            //await this.$refs.tabla.getData();
            //await this.resetValues();
        },
        getCategorias() {
            let parametros = { activo: true, paginate: false };
            apiPermisos.findCategorias(parametros).then((response) => {
                this.itemsCategorias = response.data.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },
        redireccionar(){
            this.$router.push('/dashboard');
        },
    },
    created() {
        this.datosLogin = this.$session.get('usuario');
        this.rol        = this.datosLogin.rol.name;
    },
}
</script>
<style scoped>
    
</style>